@font-face {
  font-family: 'Union';
  src: url('./js/theme/fonts/Union Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Union';
  font-weight: bold;
  src: url('./js/theme/fonts/Union Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Union';
  font-style: italic;
  src: url('./js/theme/fonts/Union Regular Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Union';
  font-style: italic;
  font-weight: bold;
  src: url('./js/theme/fonts/Union Bold Italic.otf') format('opentype');
}

body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
